import { AuthGuard } from './auth/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { roles: ['All'] }
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'apps',
    component: LayoutComponent,
    children: [
      {
        path: 'empleados',
        loadComponent: () =>
          import(
            './pages/apps/empleados/empleados.component'
          ).then((m) => m.EmpleadosComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'categorias',
        loadComponent: () =>
          import(
            './pages/apps/categorias/categorias.component'
          ).then((m) => m.CategoriasComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'productos',
        loadComponent: () =>
          import(
            './pages/apps/productos/productos.component'
          ).then((m) => m.ProductosComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'clientes',
        loadComponent: () =>
          import(
            './pages/apps/clientes/clientes.component'
          ).then((m) => m.ClientesComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'ventas',
        loadComponent: () =>
          import(
            './pages/apps/ventas/ventas.component'
          ).then((m) => m.VentasComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'recepcion',
        loadComponent: () =>
          import(
            './pages/apps/recepcion/recepcion.component'
          ).then((m) => m.RecepcionComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'pedidos',
        loadComponent: () =>
          import(
            './pages/apps/pedido/pedido.component'
          ).then((m) => m.PedidoComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'diarios',
        loadComponent: () =>
          import(
            './pages/apps/diarios/diarios.component'
          ).then((m) => m.DiariosComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'reportes',
        loadComponent: () =>
          import(
            './pages/apps/reportes/reportes.component'
          ).then((m) => m.ReportesComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'jugos',
        loadComponent: () =>
          import(
            './pages/apps/jugos/jugos.component'
          ).then((m) => m.JugosComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
      {
        path: 'helados',
        loadComponent: () =>
          import(
            './pages/apps/helados/helados.component'
          ).then((m) => m.HeladosComponent),
        canActivate: [AuthGuard],
        data: { roles: ['All'] }
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];
//apps/imprentas'
